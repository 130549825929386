import { useDisableScroll } from '@keyliving/component-lib';
import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';

import { ReactComponent as KeyResponsiveLogo } from '../../assets/icons/key-logo-responsive.svg';
import { SUPPORT_EMAIL } from '../../constants';
import { AccountDropdown, AccountMenu } from './Account';
// import Dropdown from './Dropdown';
import classes from './Nav.module.scss';
import Toggle from './Toggle';

const cx = classnames.bind(classes);

export function Navigation() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [blockScroll, allowScroll] = useDisableScroll();

    useEffect(() => {
        if (isOpen) {
            blockScroll();
        }

        return allowScroll;
    }, [allowScroll, blockScroll, isOpen]);

    return (
        <>
            <div
                className={cx('wrapper', {
                    'nav-bar--open': isOpen,
                })}
            >
                <div className={classes.container}>
                    <div className={cx('nav-bar')}>
                        <div className={classes.main}>
                            <Toggle
                                isOpen={isOpen}
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}
                            />
                            <div>
                                <a className={classes.home} href="https://keyown.com/">
                                    <KeyResponsiveLogo className={classes['brand-logo']} />
                                </a>
                            </div>
                            <div className={classes['nav-wrapper']}>
                                <div className={classes['nav-container']}>
                                    <nav
                                        aria-label="Main Menu"
                                        className={classes.nav}
                                        role="navigation"
                                    >
                                        <a
                                            className={classes.nav__item}
                                            href="https://www.keyown.com/aspiring-owners"
                                        >
                                            How it Works
                                        </a>
                                        <a
                                            className={classes.nav__item}
                                            href="https://www.keyown.com/about-key"
                                        >
                                            About Key
                                        </a>
                                        {/* <Dropdown
                                            className={classes['main-nav-item']}
                                            id="aspiring-owners"
                                            items={[
                                                {
                                                    label: 'How it works',
                                                    url: 'https://www.keyown.com/aspiring-owners',
                                                },
                                                {
                                                    label: 'Co-own our available homes',
                                                    url: 'https://homes.keyown.com',
                                                },
                                                {
                                                    label: 'Co-own with your landlord',
                                                    url: 'https://www.keyown.com/renters-movement',
                                                },
                                                {
                                                    label: 'Calculator',
                                                    url: 'https://www.keyown.com/calculator',
                                                },
                                            ]}
                                            label="Aspiring Owners"
                                        />
                                        <Dropdown
                                            className={classes['main-nav-item']}
                                            id="property-owners"
                                            items={[
                                                {
                                                    label: 'How it works',
                                                    url: 'https://www.keyown.com/property-owners',
                                                },
                                                {
                                                    label: 'Calculator',
                                                    url: 'https://www.keyown.com/property-owners/calculator',
                                                },
                                            ]}
                                            label="Property Owners"
                                        />
                                        <a
                                            className={cx(
                                                classes.nav__item,
                                                classes['main-nav-item']
                                            )}
                                            href="https://keyown.com/about-key"
                                        >
                                            About
                                        </a>
                                        <Dropdown
                                            className={classes['main-nav-item']}
                                            id="more"
                                            items={[
                                                {
                                                    label: 'News',
                                                    url: 'https://keyown.com/news',
                                                },
                                                {
                                                    label: 'Blog',
                                                    url: 'https://keyown.com/blog',
                                                },
                                                {
                                                    label: 'FAQs',
                                                    url: 'https://keyown.com/faq',
                                                },
                                                {
                                                    label: 'Realtors',
                                                    url: 'https://www.keyown.com/realtors',
                                                },
                                                {
                                                    label: 'Contact us',
                                                    url: 'https://www.keyown.com/sign-up-form',
                                                },
                                            ]}
                                            label="More"
                                        /> */}
                                        <AccountMenu
                                            onClick={() => {
                                                // Close menu on selection
                                                setIsOpen(false);
                                            }}
                                        />
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className={classes.utility}>
                            <AccountDropdown />
                            {/* <a
                                className={cx(classes.utility__cta)}
                                href="https://www.keyown.com/property-owners/get-started"
                            >
                                List your rental
                            </a> */}
                            <a
                                className={cx(classes.utility__cta)}
                                href={`mailto:${SUPPORT_EMAIL}?subject=Interested in available homes&body=Please specify if you're interested in any of the available homes or if you have any questions. Our team will be happy to support you.`}
                            >
                                Start owning
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
